/* eslint-disable ember/require-computed-macros */
import { computed } from '@ember/object';
import { not } from '@ember/object/computed';

import { buildValidations, validator } from 'ember-cp-validations';

import { CLIENT_KINDS } from 'qonto/constants/clients';

export default buildValidations({
  kind: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      messageKey: 'receivable-invoices.customer-creation.errors.required-field',
    }),
    validator('inline', {
      disabled: computed('model.billingAddress.countryCode', function () {
        return this.model.billingAddress?.countryCode === 'IT';
      }),
      validate(value) {
        return value !== CLIENT_KINDS.FREELANCER
          ? true
          : this.createErrorMessage('validations.errors.invalid');
      },
    }),
  ],
  name: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: computed('model.{firstName,lastName,isCompany,isSddClient}', function () {
        return this.model.isSddClient
          ? !this.model.isCompany
          : this.model.firstName && this.model.lastName;
      }),
      messageKey: 'receivable-invoices.customer-creation.errors.required-field',
    }),
  ],
  firstName: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: computed('model.{name,isCompany,isSddClient}', function () {
        return this.model.isSddClient ? this.model.isCompany : this.model.name;
      }),
      messageKey: 'receivable-invoices.customer-creation.errors.required-field',
    }),
  ],
  lastName: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: computed('model.{name,isCompany,isSddClient}', function () {
        return this.model.isSddClient ? this.model.isCompany : this.model.name;
      }),
      messageKey: 'receivable-invoices.customer-creation.errors.required-field',
    }),
  ],
  email: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: not('model.isSddClient'),
      messageKey: 'receivable-invoices.customer-creation.errors.required-field',
    }),
    validator('format', {
      type: 'email',
      disabled: computed('model.email', function () {
        return !this.model.email;
      }),
      minTldLength: 2,
      messageKey: 'receivable-invoices.customer-creation.errors.invalid-email',
    }),
  ],
  tinNumber: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: computed('model.{billingAddress.countryCode,isCompany,isSddClient}', function () {
        return (
          this.model.isSddClient ||
          ['DE'].includes(this.model.billingAddress?.countryCode) ||
          (this.model.billingAddress?.countryCode !== 'IT' && !this.model.isCompany)
        );
      }),
      messageKey: 'receivable-invoices.customer-creation.errors.required-field',
    }),
    validator('length', {
      min: 11,
      max: 16,
      disabled: computed('model.billingAddress.countryCode', function () {
        return this.model.billingAddress?.countryCode !== 'IT';
      }),
      messageKey: 'validations.errors.invalid',
    }),
  ],
  eInvoicingAddress: [validator('ds-error')],
  vatNumber: [
    validator('ds-error'),
    validator('length', {
      min: 3,
      max: 30,
      disabled: computed('model.{vatNumber,billingAddress.countryCode}', function () {
        return !this.model.vatNumber || this.model.billingAddress?.countryCode !== 'IT';
      }),
      messageKey: 'validations.errors.invalid',
    }),
    validator('inline', {
      disabled: computed('model.{vatNumber,billingAddress.countryCode}', function () {
        return !this.model.vatNumber || this.model.billingAddress?.countryCode !== 'IT';
      }),
      validate(value) {
        return value.startsWith('IT')
          ? true
          : this.createErrorMessage('validations.errors.invalid');
      },
    }),
  ],
  currency: [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      messageKey: 'receivable-invoices.customer-creation.errors.required-field',
    }),
  ],
  recipientCode: [
    validator('ds-error'),
    validator('length', {
      is: 7,
      disable: computed('model.{recipientCode,billingAddress.countryCode}', function () {
        return !this.model.recipientCode || this.model.billingAddress?.countryCode !== 'IT';
      }),
      messageKey: 'receivable-invoices.customer-creation.errors.invalid-recipient-code',
    }),
  ],

  // Billing Address
  'billingAddress.streetAddress': [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: computed('model.isSddClient', function () {
        return this.model.isSddClient;
      }),
      messageKey: 'receivable-invoices.customer-creation.errors.required-field',
    }),
  ],
  'billingAddress.city': [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: computed('model.isSddClient', function () {
        return this.model.isSddClient;
      }),
      messageKey: 'receivable-invoices.customer-creation.errors.required-field',
    }),
  ],
  'billingAddress.countryCode': [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: computed('model.isSddClient', function () {
        return this.model.isSddClient;
      }),
      messageKey: 'receivable-invoices.customer-creation.errors.required-field',
    }),
  ],
  'billingAddress.provinceCode': [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      messageKey: 'receivable-invoices.customer-creation.errors.required-field',
      disabled: computed('model.{billingAddress.countryCode,isSddClient}', function () {
        return this.model.billingAddress?.countryCode !== 'IT' || this.model.isSddClient;
      }),
    }),
  ],
  'billingAddress.zipCode': [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: computed('model.isSddClient', function () {
        return this.model.isSddClient;
      }),
      messageKey: 'receivable-invoices.customer-creation.errors.required-field',
    }),
    validator('number', {
      allowString: true,
      integer: true,
      disabled: computed('model.billingAddress.countryCode', function () {
        return this.model.billingAddress?.countryCode !== 'IT';
      }),
      messageKey: 'receivable-invoices.customer-creation.errors.zip-code-it',
    }),
    validator('length', {
      is: 5,
      disabled: computed('model.billingAddress.countryCode', function () {
        return this.model.billingAddress?.countryCode !== 'IT';
      }),
      messageKey: 'receivable-invoices.customer-creation.errors.zip-code-it',
    }),
  ],

  // Delivery Address (fields are only required if delivery address switch toggle is ON)
  'deliveryAddress.streetAddress': [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: computed('model.deliveryAddress', function () {
        return !this.model.deliveryAddress;
      }),
      messageKey: 'receivable-invoices.customer-creation.errors.required-field',
    }),
  ],
  'deliveryAddress.city': [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: computed('model.deliveryAddress', function () {
        return !this.model.deliveryAddress;
      }),
      messageKey: 'receivable-invoices.customer-creation.errors.required-field',
    }),
  ],
  'deliveryAddress.countryCode': [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: computed('model.deliveryAddress', function () {
        return !this.model.deliveryAddress;
      }),
      messageKey: 'receivable-invoices.customer-creation.errors.required-field',
    }),
  ],
  'deliveryAddress.zipCode': [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: computed('model.deliveryAddress', function () {
        return !this.model.deliveryAddress;
      }),
      messageKey: 'receivable-invoices.customer-creation.errors.required-field',
    }),
    validator('number', {
      allowString: true,
      integer: true,
      disabled: computed('model.{deliveryAddress,deliveryAddress.countryCode}', function () {
        return !this.model.deliveryAddress || this.model.deliveryAddress.countryCode !== 'IT';
      }),
      messageKey: 'receivable-invoices.customer-creation.errors.zip-code-it',
    }),
    validator('length', {
      is: 5,
      disabled: computed('model.{deliveryAddress,deliveryAddress.countryCode}', function () {
        return !this.model.deliveryAddress || this.model.deliveryAddress.countryCode !== 'IT';
      }),
      messageKey: 'receivable-invoices.customer-creation.errors.zip-code-it',
    }),
  ],
  'deliveryAddress.provinceCode': [
    validator('ds-error'),
    validator('presence', {
      presence: true,
      disabled: computed('model.deliveryAddress.countryCode', function () {
        return !this.model.deliveryAddress || this.model.deliveryAddress.countryCode !== 'IT';
      }),
      messageKey: 'receivable-invoices.customer-creation.errors.required-field',
    }),
  ],
});
