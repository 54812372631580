import Service, { service, type Registry as Services } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { dropTask } from 'ember-concurrency';

import { apiBaseURL, mollieNamespace } from 'qonto/constants/hosts';
import { MOLLIE_CONNECTION_STATUS } from 'qonto/constants/mollie-connection';

interface MollieConnection {
  organization_id: string;
  status: keyof typeof MOLLIE_CONNECTION_STATUS;
  user_bank_account_id: string;
}

export default class MollieService extends Service {
  @service declare organizationManager: Services['organizationManager'];
  @service declare networkManager: Services['networkManager'];

  fetchConnectionTask = dropTask(
    waitFor(async () => {
      let previousValue: MollieConnection = this.fetchConnectionTask.lastSuccessful?.value;
      let currentOrganizationId = this.organizationManager.organization.id;
      if (previousValue?.organization_id === currentOrganizationId) {
        return previousValue;
      } else {
        let response = await this.networkManager.request(
          `${apiBaseURL}/${mollieNamespace}/connections`
        );
        return (
          response.data?.[0]?.attributes || {
            status: MOLLIE_CONNECTION_STATUS.NOT_CONNECTED,
            organization_id: currentOrganizationId,
          }
        );
      }
    })
  );

  get status() {
    return this.fetchConnectionTask.last?.value?.status;
  }

  get bankAccountId() {
    return this.fetchConnectionTask.last?.value?.user_bank_account_id;
  }

  get hasOnboarded() {
    return this.isEnabled || this.isPending || this.isDisabled;
  }

  get isNotConnected() {
    return this.status === MOLLIE_CONNECTION_STATUS.NOT_CONNECTED;
  }

  get isEnabled() {
    return this.status === MOLLIE_CONNECTION_STATUS.ENABLED;
  }

  get isPending() {
    return this.status === MOLLIE_CONNECTION_STATUS.PENDING;
  }

  get isDisabled() {
    return this.status === MOLLIE_CONNECTION_STATUS.DISABLED;
  }

  get isError() {
    return this.fetchConnectionTask.lastComplete?.isError;
  }
}

declare module '@ember/service' {
  interface Registry {
    mollie: MollieService;
  }
}
