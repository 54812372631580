import BaseInvoicingDocumentSerializer from './receivable-invoice/base';

export default class ReceivableInvoiceSerializer extends BaseInvoicingDocumentSerializer {
  serialize(snapshot, option) {
    let json = super.serialize(snapshot, option);

    if (this.store.adapterFor('receivableInvoice').namespace === 'v5') {
      delete json.data.attributes.performance_date;
    } else {
      delete json.data.attributes.performance_end_date;
      delete json.data.attributes.performance_start_date;
    }

    return json;
  }

  normalizeFindRecordResponse(_store, _primaryModelClass, payload) {
    payload.data.attributes.next_reminder_date = payload.data.attributes.next_reminder_date ?? null;
    return super.normalizeFindRecordResponse(...arguments);
  }
}
