export enum EligibilityStatus {
  Eligible = 'eligible',
  Ineligible = 'ineligible',
}

export enum IneligibilityReason {
  KYBNotApproved = 'kyb_not_approved',
  FlexKYBNotApproved = 'flex_kyb_not_approved',
  Unknown = 'unknown',
}

interface EligibilityInformation {
  /**
   * The eligibility status of the entity.
   */
  status: EligibilityStatus;

  /**
   * The reason for ineligibility.
   */
  reason: string;
}

interface Eligible extends EligibilityInformation {
  status: EligibilityStatus.Eligible;
  reason: '';
}

interface Ineligible extends EligibilityInformation {
  status: EligibilityStatus.Ineligible;
  reason: IneligibilityReason;
}

export type Eligibility = Eligible | Ineligible;

export interface Amount<T extends number | string> {
  /**
   * The amount value.
   * @example 100
   */
  value: T;

  /**
   * The currency code (ISO 4217 Alphabetic Code) associated to the amount.
   * @example "USD"
   */
  currency: string;
}

export interface Currency {
  /**
   * The currency code (ISO 4217 Alphabetic Code).
   * @example "USD"
   */
  code: string;

  /**
   * The country code from where the currency is originated (ISO 3166-1 Alpha-2 Code).
   * @example "US"
   */
  countryCode: string;

  /**
   * Indicates the priority level for suggesting the currency.
   * Higher values suggest higher priority.
   * A `null` value indicates the currency should not be suggested.
   * @example 1
   */
  suggestionPriority: number | null;
}

export interface Quote {
  /**
   * The ID of the quote.
   */
  id: string;

  /**
   * The estimated delivery date (UTC-format string) of the transfer.
   * @example "2024-10-01T11:15:00Z"
   */
  estimatedDelivery: string;

  /**
   * The date (UTC-format string) the locked rate will expire.
   * @example "2024-09-30T17:17:53Z"
   */
  expirationTime: string;

  /**
   * The estimated delivery date (formatted as a human readable string) of the transfer.
   * @example "tomorrow"
   */
  formattedEstimatedDelivery: string;

  /**
   * The mechanism used to fund the transfer.
   * @example "BANK_TRANSFER"
   */
  payIn: string;

  /**
   * The mechanism used to deliver the transfer.
   * @example "SHA"
   */
  payOut: string;

  /**
   * The exchange rate value used for the conversion.
   * @example 1.11469
   */
  rate: number;

  /**
   * Whether the exchange rate value is fixed or floating.
   * A fixed rate is set firmly by the monetary authority.
   * A floating rate is determined by the market (i.e. demand and supply) and generally fluctuates constantly.
   * @example "FIXED"
   */
  rateType: 'FIXED' | 'FLOATING';

  /**
   * The source amount.
   * @example 8.97
   */
  sourceAmount: number;

  /**
   * The source currency (ISO 4217 Alphabetic Code).
   * @example "EUR"
   */
  sourceCurrency: string;

  /**
   * The target amount.
   * @example 10
   */
  targetAmount: number;

  /**
   * The target currency (ISO 4217 Alphabetic Code).
   * @example "USD"
   */
  targetCurrency: string;

  /**
   * Whether the quote was created as from the source or the target point of view.
   * @example "TARGET"
   */
  type: 'SOURCE' | 'TARGET';
}

export interface Beneficiary {
  /**
   * The ID of the beneficiary.
   */
  id: string;

  /**
   * The name of the beneficiary.
   * @example "John Doe"
   */
  name: string;

  /**
   * The country code (ISO 3166-1 Alpha-2 Code) of the beneficiary.
   * @example "US"
   */
  country: string;

  /**
   * The currency code (ISO 4217 Alphabetic Code) of the beneficiary.
   * @example "USD"
   */
  currency: string;

  /**
   * The payment method type of the beneficiary.
   * @example "aba"
   */
  paymentType: string;

  /**
   * The account identifier of the beneficiary.
   * @example "8332079350"
   */
  accountIdentifier: string;

  /**
   * The bank identifier of the beneficiary.
   * @example "064209588"
   */
  bankIdentifier?: string | null;

  /**
   * The branch identifier of the beneficiary.
   */
  branchIdentifier?: string | null;
}

export interface BeneficiaryAccountDetail {
  /**
   * The key that identifies the account detail.
   * @example "accountIdentifier"
   */
  key: string;

  /**
   * The name of the account detail.
   * @example "IBAN"
   */
  title: string;

  /**
   * The value of the account detail.
   * @example "GB29NWBK60161331926819"
   */
  value: string;
}

/**
 * The provider account, corresponding to the recipient entity stored by the provider.
 */
export interface ProviderAccount {
  /**
   * The ID of the provider account.
   */
  id: number;
}

export interface Field {
  /**
   * The key that identifies the field.
   * @example "reference"
   */
  key: string;

  /**
   * The name of the field.
   * @example "Reference"
   */
  name: string;

  /**
   * The type of the field.
   * @example "text"
   */
  type: 'date' | 'radio' | 'select' | 'text';

  /**
   * Indicates if the requirements should be refreshed on change to discover required lower level fields.
   * @example true
   */
  refreshRequirementsOnChange: boolean;

  /**
   * Indicates whether the field is required.
   * @example true
   */
  required: boolean;

  /**
   * The display format pattern.
   */
  displayFormat: string | null;

  /**
   * The example value of the field. Can be an empty string. Can be used to display a placeholder.
   * @example "Invoice #1234"
   */
  example: string;

  /**
   * The minimum length allowed for the field. Only specified in case of a field of type `"text"`.
   * @example 1
   */
  minLength: number | null;

  /**
   * The maximum length allowed for the field. Only specified in case of a field of type `"text"`.
   * @example 255
   */
  maxLength: number | null;

  /**
   * The regular expression to validate the field format. Only specified in case of a field of type `"text"`.
   * @example "/^[a-zA-Z0-9]*$/"
   */
  validationRegexp: RegExp | null;

  /**
   * The values allowed for the field. Can be used to populate a field of type `"select"` or `"radio"`. Is `null` otherwise.
   */
  valuesAllowed: Array<{ key: string; name: string }> | null;
}

export interface FieldsGroup {
  /**
   * The fields group description.
   * @example "Reference"
   */
  name: string;

  /**
   * The group of fields. Can include from `1` to `N` fields.
   */
  group: Field[];
}

export interface Requirements {
  /**
   * The type of the requirements.
   * @example "transfer"
   */
  type: string;

  /**
   * The title of the requirement.
   * @example "Transfer"
   */
  title?: string;

  /**
   * A user-facing information about the set of requirements.
   * @example "Please provide the following information to proceed with the transfer."
   */
  usageInfo?: string | null;

  /**
   * The set of fields groups to use in order to build a dynamic user interface. Can include from `1` to `N` groups.
   */
  fields: FieldsGroup[];
}

export interface Transfer {
  /**
   * The ID of the transfer stored by Qonto.
   */
  id: string;

  /**
   * The ID of the bank account.
   */
  bankAccountId: string;

  /**
   * The ID of the beneficiary.
   */
  beneficiaryId: string;

  /**
   * The ID of the membership which initiated the transfer.
   */
  initiatorId: string;

  /**
   * The ID of the organization.
   */
  organizationId: string;

  /**
   * The name of the outgoing international transfer provider.
   * @example "wise"
   */
  provider: string;

  /**
   * The ID of the transfer stored by the outgoing international transfer provider.
   */
  providerObjectId: string;

  /**
   * The source amount (value and currency code).
   * @example { value: "90", currency: "EUR" }
   */
  sourceAmount: Amount<string>;

  /**
   * The target amount (value and currency code).
   * @example { value: "100", currency: "USD" }
   */
  targetAmount: Amount<string>;

  /**
   * The status of the transfer.
   * @example "INITIATED"
   */
  status: string;
}

export interface Fees {
  /**
   * The fixed fee amount.
   *  @example 0
   */
  fix: number;

  /**
   * The minimum fee amount.
   * @example 5
   */
  minimum: number;

  /**
   * The total fee amount.
   * @example 5
   */
  total: number;

  /**
   * The variable fee rate.
   * @example 0.56
   */
  variable: number;
}

export interface Spendings {
  /**
   * The current month spending value of the membership.
   * @example 100
   */
  currentMonth: number;

  /**
   * The monthly transfer limit value of the membership.
   * @example 1000
   */
  monthlyLimit: number;

  /**
   * The per transfer limit value of the membership.
   * @example 50
   */
  perTransferLimit: number;
}

interface BaseConfirmation {
  /**
   * A list of warning codes as strings. The list can be empty.
   * @example ["already_issued"]
   */
  warnings: string[];

  /**
   * Indicates whether the transfer can be submitted.
   * @example true
   */
  canProcess: boolean;

  /**
   * Indicates if any spending limit is exceeded.
   * @example false
   */
  isAboveLimit: boolean;
}

export interface ConfirmationWithLimit extends BaseConfirmation {
  isAboveLimit: true;
  limitType: string;
  spendings: Spendings;
}

export interface ConfirmationWithoutLimit extends BaseConfirmation {
  isAboveLimit: false;
  limitType?: never;
  spendings?: never;
}

export type Confirmation = ConfirmationWithLimit | ConfirmationWithoutLimit;

export enum ProcessingEventName {
  Creation = 'creation',
  Validation = 'validation',
  Shipment = 'shipment',
  Completion = 'completion',
}

export enum ProcessingEventStatus {
  Completed = 'completed',
  InProgress = 'in_progress',
  Awaiting = 'awaiting',
}

export interface ProcessingEvent {
  /**
   * The name of the transfer processing event. Can be one of `"creation"`, `"validation"`, `"shipment"`, or `"completion"`.
   * @example "creation"
   */
  name: ProcessingEventName;

  /**
   * The name on which the transfer processing event has happened or should happen. Always defined if the status of the event is set to `"completed"`.
   * @example "2024-09-30T17:17:53Z"
   */
  date: string | null;

  /**
   * The order of the event in the chain (`[0-3]`).
   * @example 0
   */
  order: number;

  /**
   * The status of the event. Can be one of `"completed"`, `"in_progress"`, or `"awaiting"`.
   * @example "completed"
   */
  status: ProcessingEventStatus;
}

export interface AccountInformation
  extends Pick<Beneficiary, 'accountIdentifier' | 'bankIdentifier' | 'branchIdentifier'> {
  accountType: Beneficiary['paymentType'];
}
