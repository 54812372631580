// @ts-nocheck
import Service, { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import {
  PAY_LATER_ERRORS_PRIORITY,
  PAY_LATER_VALIDATION_ERROR_TYPES,
  PAY_LATER_VALIDATION_ERRORS,
} from 'qonto/constants/financing';
import {
  apiBaseURL,
  financingNamespace,
  financingV3Namespace,
  financingV4Namespace,
} from 'qonto/constants/hosts';
import pushPayload from 'qonto/utils/store-push-payload';
import transformKeys from 'qonto/utils/transform-keys';

const INVALID_DATE_ERRORS = [
  PAY_LATER_VALIDATION_ERRORS.INVALID_ISSUE_DATE,
  PAY_LATER_VALIDATION_ERRORS.INVALID_DUE_DATE,
];
const MISSING_DETAILS_ERRORS = [
  PAY_LATER_VALIDATION_ERRORS.MISSING_INVOICE_AMOUNT,
  PAY_LATER_VALIDATION_ERRORS.MISSING_ISSUE_DATE,
  PAY_LATER_VALIDATION_ERRORS.MISSING_SUPPLIER_IDENTIFIER,
  PAY_LATER_VALIDATION_ERRORS.MISSING_SUPPLIER_NAME,
];

export default class FinancingService extends Service {
  @service networkManager;
  @service organizationManager;
  @service store;

  #payLaterEligibility = null;
  #repaymentOptions = null;

  async checkPayLaterEligibility() {
    let response = await this.networkManager.request(
      `${apiBaseURL}/${financingNamespace}/pay_later/eligibility?organization_id=${this.organizationManager.organization.id}`
    );

    this.#payLaterEligibility = transformKeys(response);
    return this.#payLaterEligibility;
  }

  async getLastPayLaterEligibility() {
    return this.#payLaterEligibility ?? (await this.checkPayLaterEligibility());
  }

  get repaymentOptions() {
    return this.#repaymentOptions;
  }

  async requestPayLaterSignature() {
    let { organization, membership } = this.organizationManager;
    let response = await this.networkManager.request(
      `${apiBaseURL}/${financingNamespace}/pay_later/signature_requests`,
      {
        method: 'POST',
        data: JSON.stringify({
          organization_id: organization.id,
          signer_membership_id: membership.id,
        }),
      }
    );

    return transformKeys(response);
  }

  /**
   *
   * @param {object} amount Transfer amount MoneyObject
   * @param {string} amount.value
   * @param {string} amount.currency
   */
  async getPayLaterTransferInstallments(amount) {
    let { organization } = this.organizationManager;

    let response = await this.networkManager.request(
      `${apiBaseURL}/${financingV3Namespace}/pay_later/preview_request`,
      {
        method: 'POST',
        data: JSON.stringify({
          organization_id: organization.id,
          amount,
        }),
      }
    );

    return transformKeys(response);
  }

  /**
   * Fetches the early repayment options for a given financing
   *
   * @param {string} financingId - Financing ID
   */
  async fetchEarlyRepaymentOptions(financingId) {
    let response = await this.networkManager.request(
      `${apiBaseURL}/${financingV3Namespace}/pay_later/financings/${financingId}/early_repayment`
    );

    this.#repaymentOptions = transformKeys(response).repaymentOptions;
    return this.#repaymentOptions;
  }

  /**
   * Fetches the early repayment options for a given financing
   *
   * @param {string} financingId - Financing ID
   * @param {string} repaymentOption - Repayment option `full_repayment` or `next_installment`
   */
  async postEarlyRepayment(financingId, repaymentOption) {
    let response = await this.networkManager.request(
      `${apiBaseURL}/${financingV3Namespace}/pay_later/financings/${financingId}/early_repayment`,
      {
        method: 'POST',
        data: JSON.stringify({
          repayment_option: repaymentOption,
        }),
      }
    );

    return transformKeys(response);
  }

  async fetchRepaymentInsights() {
    let response = await this.networkManager.request(
      `${apiBaseURL}/${financingNamespace}/pay_later/insights/repayment_status?organization_id=${this.organizationManager.organization.id}`
    );

    return transformKeys(response);
  }

  /**
   * Fetches the tab counts according to given installment status filters
   *
   * @param {Record<string, string[]>} filters - Filters object with dynamic key and value pairs {@link FINANCING_INSTALLMENT_STATUS}
   * @returns {Promise<Record<string, number>>} - Resolves to a record with the tab counts per requested key
   */
  async fetchTabInsights(filters) {
    let params = new URLSearchParams();

    for (let key in filters) {
      for (let value of filters[key]) {
        params.append(`filter[${key}][]`, value);
      }
    }

    params.append('organization_id', this.organizationManager.organization.id);

    let response = await this.networkManager.request(
      `${apiBaseURL}/${financingNamespace}/pay_later/insights/installments?${params.toString()}`
    );

    let insights = {};

    if (response.insights) {
      response.insights.forEach(insight => {
        insights[insight.label] = insight.data.value;
      });
    }

    return insights;
  }

  /**
   * Fetches the tab counts according to given financing status filters
   *
   * @param {string[]} statuses - Filters array with string value{@link FINANCING_TRANSFER_STATUS}
   * @param {string} key - Key to be used in the response object
   * @returns {Promise<Record<string, number>>} - Resolves to a record with the tab counts with requested key
   */
  async fetchFinancingsTabInsights(statuses, key) {
    let params = new URLSearchParams();

    statuses.forEach(status => {
      params.append(`filter[]`, status);
    });

    params.append('organization_id', this.organizationManager.organization.id);

    let response = await this.networkManager.request(
      `${apiBaseURL}/${financingNamespace}/pay_later/insights/financings?${params.toString()}`
    );

    let insights = {};

    if (response.insights) {
      let numberOfFinancings = response.insights.reduce(
        (acc, insight) => acc + insight.data.value,
        0
      );

      insights[key] = numberOfFinancings;
    }
    return insights;
  }

  /**
   * Validates a pay later transfer
   *
   * @param {object} params - Parameters object
   * @param {object} params.amount - Transfer amount MoneyObject
   * @param {string} params.amount.value
   * @param {string} params.amount.currency
   * @param {string} params.attachmentId - Transfer attachment id
   * @param {string} params.iban - Beneficiary IBAN
   * @param {string} params.beneficiaryName - Beneficiary name
   * @param {string} params.selectedAccountIban - Selected account IBAN
   * @param {string} params.scheduledDate - Scheduled date
   * @param {string} params.operationType - Operation type
   * @param {boolean} params.groupErrors - Group errors by type {@link PAY_LATER_VALIDATION_ERROR_TYPES}
   */
  async validatePayLaterTransfer({
    amount,
    attachmentId,
    iban,
    beneficiaryName,
    selectedAccountIban = '',
    scheduledDate = '',
    operationType = '',
    groupErrors = false,
  }) {
    let { organization, membership } = this.organizationManager;

    let data;

    data = JSON.stringify({
      amount,
      organization_id: organization.id,
      membership_id: membership.id,
      attachment_id: attachmentId,
      beneficiary_iban: iban,
      beneficiary_full_name: beneficiaryName,
      selected_account_iban: selectedAccountIban,
      scheduled_date: scheduledDate,
      operation_type: operationType,
    });

    let response = await this.networkManager.request(
      `${apiBaseURL}/${financingNamespace}/pay_later/financing/validate`,
      {
        method: 'POST',
        data,
      }
    );

    let { errors } = transformKeys(response);

    return groupErrors ? this.groupErrors(errors) : this.sortErrors(errors);
  }

  /**
   * @param {string[]} financing_status - All the financing statuses to fetch
   * Returns all the financings transfer with the given statuses
   */
  async fetchFinancings(financing_status, order = 'asc') {
    let { organization } = this.organizationManager;
    let financingNamespace = financingV4Namespace;
    let data = {
      organization_id: organization.id,
      per_page: 50,
      page: 1,
      filter: { financing_status },
      sort_by: `date:${order}`,
      include: 'installments,transactions',
    };

    let response = await this.networkManager.request(
      `${apiBaseURL}/${financingNamespace}/pay_later/financings`,
      {
        method: 'GET',
        data,
      }
    );

    return pushPayload(this.store, 'financing', response);
  }

  /**
   * Generates a financing statement for the given financing ID
   *
   * @param {string} financingId - Financing ID
   */
  async postStatementGenerate(financingId) {
    let response = await this.networkManager.request(
      `${apiBaseURL}/${financingV4Namespace}/pay_later/financings/${financingId}/statement/generate`,
      {
        method: 'POST',
      }
    );

    return transformKeys(response);
  }

  /**
   * Sorts the given array of errors based on a predefined priority list: {@link PAY_LATER_ERRORS_PRIORITY}.
   * If the input array is empty or not provided, returns `null`.
   *
   * @param {string[]} errors - The array of errors to sort.
   * @returns {string[]|null} The sorted array of errors, or `null` if the input is empty or not provided.
   */
  sortErrors(errors) {
    if (!errors || errors.length === 0) return null;

    return PAY_LATER_ERRORS_PRIORITY.filter(error => errors.includes(error));
  }

  /**
   * Groups the given array of errors by their type based on a predefined mapping.
   * If the errors array matches one type, it returns an object with the first type and the errors.
   * If no matching types are found, or the input array is empty, returns `null`.
   *
   * @param {string[]} errors - The array of errors to group by type.
   * @returns {{type: string, errors: string[]}|null} An object containing the first error type and the array of all errors, or `null` if no matching types are found or the input is empty.
   */
  groupErrors(errors) {
    let errorTypeMap = {
      [PAY_LATER_VALIDATION_ERROR_TYPES.INVALID_DATE]: INVALID_DATE_ERRORS,
      [PAY_LATER_VALIDATION_ERROR_TYPES.MISSING_DETAILS]: MISSING_DETAILS_ERRORS,
      [PAY_LATER_VALIDATION_ERROR_TYPES.SELF_TRANSFER]: [PAY_LATER_VALIDATION_ERRORS.SELF_TRANSFER],
    };

    for (let [type, errorValues] of Object.entries(errorTypeMap)) {
      if (errors?.some(error => errorValues.includes(error))) {
        return {
          type,
          errors,
        };
      }
    }

    return null;
  }

  get hasEligibilityData() {
    return Boolean(this.#payLaterEligibility);
  }
}

declare module '@ember/service' {
  interface Registry {
    financing: FinancingService;
    financing: FinancingService;
  }
}
