import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import type AbilitiesService from 'ember-can/services/abilities';
import { dropTask } from 'ember-concurrency';
import type IntlService from 'ember-intl/services/intl';
// @ts-expect-error -- not typed
import { variation } from 'ember-launch-darkly';

import { codeToName } from 'qonto/models/subscription-product';
import type { Transaction } from 'qonto/react/graphql';
// @ts-expect-error -- not typed
import { ErrorInfo } from 'qonto/utils/error-info';

import type OrganizationManager from './organization-manager';
import type SentryService from './sentry';
import type SubscriptionManagerService from './subscription-manager';

export interface SepaTransferUpsellRecommendation {
  translations: {
    subtitle: string;
    badgeText: string;
    ctaText: string;
  };
  recommendation: Recommendation;
}
interface Recommendation {
  recommendedProduct: any;
  recommendedRecurrence: 'monthly' | 'annually';
  recommendedProductFeatures: {
    code: string;
    limit: {
      value: number;
    } | null;
  }[];
}
/**
 * Upsell manager
 *
 * @class UpsellManagerService
 * @module qonto/services/upsell-manager
 * @extends Ember.Service
 */

export default class UpsellManager extends Service {
  @service subscriptionManager!: SubscriptionManagerService;
  @service abilities!: AbilitiesService;
  @service sentry!: SentryService;
  @service organizationManager!: OrganizationManager;
  @service intl!: IntlService;
  @tracked upsellRecommendations: Record<string, any> = {};

  getUpsellRecommendationTask = dropTask(async ({ featureName, shouldDisable = false }) => {
    if (!this.abilities.can('update subscription') || shouldDisable) {
      return null;
    }

    try {
      let { recommended_recurrence, recommended_product, recommended_product_features } =
        await this.subscriptionManager.upgradeRecommendation(featureName);

      this.upsellRecommendations = {
        ...this.upsellRecommendations,
        [featureName]: {
          recommendedProduct: recommended_product,
          recommendedRecurrence: recommended_recurrence,
          recommendedProductFeatures: recommended_product_features,
        },
      };

      return this.upsellRecommendations[featureName];
    } catch (error) {
      this.upsellRecommendations = { ...this.upsellRecommendations, [featureName]: null };
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.httpStatus !== 404 && errorInfo.httpStatus !== 422) {
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    }
  });

  isDisabledForSepaTransferUpsell() {
    if (
      !this.organizationManager.organization?.hasFeeUpsell ||
      (variation('feature--boolean-italy-fee-upsell') &&
        this.organizationManager.organization?.legalCountry === 'IT') ||
      this.subscriptionManager.shouldHideUpsell
    ) {
      return true;
    }
    return false;
  }

  hasAvailableFreeTrial(featureCode: string) {
    let hasFeature = this.subscriptionManager.hasFeature(featureCode);
    let hasAvailableFreeTrial = Boolean(
      this.subscriptionManager?.currentSubscription?.findTrial(featureCode)
    );
    return this.abilities.can('update subscription') && !hasFeature && hasAvailableFreeTrial;
  }

  sepaTransferUpsellRecommendation(
    transaction: Transaction,
    checkFromBilling: boolean = false
  ): SepaTransferUpsellRecommendation | null {
    let sepaTransferUpsellRecommendation = this.upsellRecommendations['sepa_out'];

    if (
      this.isDisabledForSepaTransferUpsell() ||
      !sepaTransferUpsellRecommendation ||
      !transaction
    ) {
      return null;
    }

    if (this.#isTransferSepaSubType(transaction, checkFromBilling)) {
      return {
        recommendation: sepaTransferUpsellRecommendation,
        translations: this.#sepaTransferUpsellTranslations(sepaTransferUpsellRecommendation),
      };
    }

    return null;
  }

  #sepaTransferUpsellTranslations(recommendation: Recommendation) {
    let { recommendedProduct, recommendedProductFeatures } = recommendation;

    let sepaTransferLimit = recommendedProductFeatures.find(
      feature => feature.code === 'sepa_transfers'
    )?.limit?.value;

    return {
      subtitle: this.intl.t('promotions.sepa-transfers-fees.body', {
        amount: sepaTransferLimit,
        plan_name: codeToName(this.intl, recommendedProduct.group_code),
      }),
      ctaText: this.intl.t('promotions.sepa-transfers-fees.cta'),
      badgeText: this.intl.t('promotions.sepa-transfers-fees.badge'),
    };
  }

  #isTransferSepaSubType(transaction: Transaction, checkFromBilling: boolean = false) {
    if (checkFromBilling && 'billingTransfer' in transaction) {
      return transaction.billingTransfer?.operationSubtype
        ? ['sepa_inbound_transfer', 'sepa_outbound_transfer'].includes(
            transaction.billingTransfer.operationSubtype
          )
        : false;
    }

    // @ts-ignore
    let { subject: walletToWallet } = transaction;
    let isSepaInboundTransfer = walletToWallet?.get('isSepaInboundTransfer');
    let isSepaOutboundTransfer = walletToWallet?.get('isSepaOutboundTransfer');

    return isSepaInboundTransfer || isSepaOutboundTransfer;
  }
}

declare module '@ember/service' {
  interface Registry {
    'upsell-manager': UpsellManager;
    upsellManager: UpsellManager;
  }
}
