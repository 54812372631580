import Service, { service, type Registry as Services } from '@ember/service';
import { decamelize } from '@ember/string';

import { isNil } from 'es-toolkit';

import { apiBaseURL, internationalOutNamespace } from 'qonto/constants/hosts';
import { ELIGIBILITY_STATUS } from 'qonto/constants/international-out/eligibility';
import { DEFAULT_SOURCE_CURRENCY_CODE } from 'qonto/constants/international-out/quote';
import type {
  Amount,
  Beneficiary,
  BeneficiaryAccountDetail,
  Confirmation,
  Currency,
  Eligibility,
  Fees,
  ProcessingEvent,
  ProviderAccount,
  Quote,
  Requirements,
  Transfer,
} from 'qonto/services/international-out/types';
import type { FormData } from 'qonto/utils/dynamic-form';
import {
  normalizeConfirmation,
  normalizeCurrency,
  normalizeFee,
  normalizeQuote,
  // @ts-expect-error
} from 'qonto/utils/international-out/normalize';
import removeNullValues from 'qonto/utils/remove-null-values';
import transformKeys from 'qonto/utils/transform-keys';

/**
 * Provides methods to interact with the {@link https://openapi-master.staging.qonto-cbs.co/international-out | outgoing international transfer API}.
 *
 * @public
 * @class InternationalOutManager
 * @extends Service
 * @module services/international-out-manager
 */
export default class InternationalOutManager extends Service {
  @service declare networkManager: Services['networkManager'];
  @service declare organizationManager: Services['organizationManager'];

  /**
   * Eligibility status cache for outgoing international transfers.
   *
   * It is used to store the eligibility status on an organization-level basis.
   *
   * @private
   * @default new Map()
   * @see {@link Eligibility | `Eligibility`}
   */
  #eligibilityStatus = new Map<string, Eligibility>();

  /**
   * Unique identifier used to ensure idempotency.
   *
   * @private
   * @default null
   */
  #idempotencyKey: string | null = null;

  /**
   * Generates a unique idempotency key using the `randomUUID` method of the `Crypto` interface and assigns it to the service.
   *
   * @public
   * @function addIdempotencyKey
   */
  addIdempotencyKey(): void {
    this.#idempotencyKey = crypto.randomUUID();
  }

  /**
   * Gets the idempotency key.
   *
   * @public
   * @function getIdempotencyKey
   * @returns The idempotency key.
   */
  getIdempotencyKey(): string | null {
    return this.#idempotencyKey;
  }

  /**
   * Removes the idempotency key from the service by setting it to `null`.
   *
   * @public
   * @function removeIdempotencyKey
   */
  removeIdempotencyKey(): void {
    this.#idempotencyKey = null;
  }

  /**
   * Adds the idempotency key as a header to the network manager.
   *
   * @public
   * @function addIdempotencyHeader
   */
  addIdempotencyHeader(): void {
    this.networkManager.addIdempotencyHeader(this.#idempotencyKey);
  }

  /**
   * Removes the idempotency key from the headers of the network manager.
   *
   * @public
   * @function removeIdempotencyHeader
   */
  removeIdempotencyHeader(): void {
    this.networkManager.removeIdempotencyHeader();
  }

  /**
   * Confirms a transfer.
   *
   * @public
   * @async
   * @function confirmTransfer
   * @param params - The parameters object.
   * @param params.amount - The total source amount (value and currency code), including the transfer fees.
   * @param params.bankAccountId - The ID of the bank account.
   * @param params.beneficiaryId - The ID of the beneficiary.
   * @returns A promise that resolves to the confirmation response.
   * @throws If the network request fails or if the response is invalid.
   * @see {@link https://openapi-master.staging.qonto-cbs.co/international-out#tag/Transactions/operation/confirmTransaction | Endpoint documentation}
   */
  async confirmTransfer({
    amount,
    bankAccountId,
    beneficiaryId,
  }: {
    amount: Amount<number>;
    bankAccountId: string;
    beneficiaryId: string;
  }): Promise<Confirmation> {
    const url = this.#buildURL('confirm');
    const response = await this.networkManager.request(url, {
      method: 'POST',
      data: JSON.stringify({
        payload: transformKeys(
          {
            amount: {
              ...amount,
              value: amount.value.toString(),
            },
            bankAccountId,
            beneficiaryId,
            organizationId: this.organizationManager.organization.id,
          },
          decamelize
        ),
      }),
    });
    return normalizeConfirmation(transformKeys(response));
  }

  /**
   * Creates a new beneficiary.
   *
   * @public
   * @async
   * @function createBeneficiary
   * @param params - The parameters object.
   * @param params.quoteId - The ID of the quote.
   * @param params.currency - The currency (ISO 4217 Alphabetic Code) to associate the beneficiary with.
   * @param params.type - The payment type to associate the beneficiary with.
   * @param params.details - The attributes to create the beneficiary with.
   * @returns A promise that resolves to an object containing the patched quote, the newly created beneficiary, the transfer fees and the target account ID.
   * @throws If the network request fails or if the response is invalid.
   * @see {@link https://openapi-master.staging.qonto-cbs.co/international-out#tag/Beneficiaries/operation/createBeneficiary | Endpoint documentation}
   */
  async createBeneficiary({
    quoteId,
    currency,
    type,
    details,
  }: {
    quoteId: string;
    currency: string;
    type: string;
    details: FormData;
  }): Promise<{
    beneficiary: Beneficiary;
    fees: Fees;
    quote: Quote;
    targetAccountId: ProviderAccount['id'];
  }> {
    const url = this.#buildURL('beneficiaries');
    const response = await this.networkManager.request(url, {
      method: 'POST',
      data: JSON.stringify({
        quoteId,
        payload: {
          currency,
          details,
          type,
        },
      }),
    });
    const { fees, quote, ...rest } = transformKeys(response);
    return { fees: normalizeFee(fees), quote: normalizeQuote(quote), ...rest };
  }

  /**
   * Creates the quote resource required to create an internatinal transfer.
   * It contains useful information such as the exchange rate, the estimated delivery time and the methods the user can pay for the transfer.
   *
   * @public
   * @async
   * @function createQuote
   * @param params - The data object containing amount and currency information to use as a payload for the request.
   * @param params.targetAmount - The target amount.
   * @param params.sourceAmount - The source amount.
   * @param params.targetCurrency - The target currency (ISO 4217 Alphabetic Code).
   * @param params.sourceCurrency - The source currency (ISO 4217 Alphabetic Code).
   * @returns A promise that resolves to an object containing the fees and quote objects.
   * @throws If the network request fails or if the response is invalid.
   * @see {@link https://openapi-master.staging.qonto-cbs.co/international-out#tag/Quotes/operation/createQuote | Endpoint documentation}
   */
  async createQuote({
    sourceAmount,
    targetAmount,
    ...params
  }: {
    targetAmount: number | null;
    sourceAmount: number | null;
    targetCurrency: string;
    sourceCurrency: string;
  }): Promise<{ fees: Fees; quote: Quote }> {
    const url = this.#buildURL('quote');
    const response = await this.networkManager.request(url, {
      method: 'POST',
      data: JSON.stringify({
        ...(sourceAmount && { sourceAmountVal: sourceAmount.toString() }),
        ...(targetAmount && { targetAmountVal: targetAmount.toString() }),
        ...params,
      }),
    });
    const { fees, quote } = transformKeys(response);
    return { fees: normalizeFee(fees), quote: normalizeQuote(quote) };
  }

  /**
   * Creates the transfer (i.e. payment order) to the target account based on the quote resource.
   * Once created, the transfer needs to be funded. Otherwise, it will be automatically canceled.
   *
   * @public
   * @async
   * @function createTransfer
   * @param params - The parameters object.
   * @param [params.attachmentIds=[]] - A list of attachment IDs.
   * @param params.bankAccountId - The ID of the bank account.
   * @param params.beneficiaryId - The ID of the beneficiary stored by Qonto.
   * @param params.quoteId - The ID of the quote.
   * @param params.targetAccountId - The ID of the beneficiary stored by the outgoing international transfer provider.
   * @param params.details - The transfer details.
   * @param params.sourceAmount - The source amount (value and currency code), including the transfer fees.
   * @param params.targetAmount - The target amount (value and currency code).
   * @param [params.metadata] - The metadata object.
   * @returns A promise that resolves to the transfer object.
   * @throws If the network request fails or if the response is invalid.
   * @see {@link https://openapi-master.staging.qonto-cbs.co/international-out#tag/Transactions/operation/createTransaction | Endpoint documentation}
   */
  async createTransfer({
    attachmentIds = [],
    bankAccountId,
    beneficiaryId,
    quoteId,
    targetAccountId,
    details,
    sourceAmount,
    targetAmount,
    metadata = {},
  }: {
    attachmentIds?: string[];
    bankAccountId: string;
    beneficiaryId: string;
    quoteId: string;
    targetAccountId: number;
    details: FormData;
    sourceAmount: Amount<number>;
    targetAmount: Amount<number>;
    metadata?: Record<string, string>;
  }): Promise<Transfer> {
    const url = this.#buildURL('transfers');
    const { transfer } = await this.networkManager.request(url, {
      method: 'POST',
      data: JSON.stringify({
        transfer: {
          attachmentIds,
          bankAccountId,
          beneficiaryId,
          quoteId,
          targetAccountId,
          details,
          totalSourceAmount: {
            ...sourceAmount,
            value: sourceAmount.value.toString(),
          },
          targetAmount: {
            ...targetAmount,
            value: targetAmount.value.toString(),
          },
        },
        metadata,
      }),
    });
    return transformKeys(transfer);
  }

  /**
   * Deletes a beneficiary.
   *
   * @public
   * @async
   * @function deleteBeneficiary
   * @param beneficiary - The beneficiary to delete.
   * @throws If the network request fails or if the response is invalid.
   * @see {@link https://openapi-master.staging.qonto-cbs.co/international-out#tag/Beneficiaries/operation/deleteBeneficiary | Endpoint documentation}
   */
  async deleteBeneficiary({ id }: { id: string }): Promise<void> {
    const url = this.#buildURL(`beneficiaries/${id}`);
    await this.networkManager.request(url, {
      method: 'DELETE',
    });
  }

  /**
   * Retrieves the additional beneficiary requirements for a given quote and beneficiary.
   * Are returned only the requirements that are not explicitely marked as optional.
   *
   * @public
   * @async
   * @function getAdditionalBeneficiaryRequirements
   * @param params - The parameters object.
   * @param params.quoteId - The ID of the quote.
   * @param params.beneficiary - The beneficiary object to get the additional requirements for.
   * @param params.beneficiary.id - The ID of the selected beneficiary.
   * @param params.beneficiary.currency - The currency of the selected beneficiary.
   * @param params.beneficiary.paymentType - The payment type of the selected beneficiary.
   * @param [params.details] - The details containing the form data.
   * @returns A promise that resolves to the additional beneficiary requirements.
   * @throws If the network request fails or if the response is invalid.
   */
  async getAdditionalBeneficiaryRequirements({
    quoteId,
    beneficiary,
    details = {},
  }: {
    quoteId: string;
    beneficiary: Beneficiary;
    details?: FormData;
  }): Promise<Requirements[]> {
    const additionalRequirements = await this.getBeneficiaryRequirements({
      quoteId,
      currency: beneficiary.currency,
      beneficiary,
      details,
    });

    const requiredAdditionalRequirements = additionalRequirements
      ?.map(accountType => ({
        ...accountType,
        fields: accountType.fields
          .map(({ group }) => group)
          .flat()
          .filter(({ required }) => required),
      }))
      .filter(accountType => accountType.fields.length > 0);

    return additionalRequirements?.filter(first =>
      requiredAdditionalRequirements.some(second => first.type === second.type)
    );
  }

  /**
   * Retrieves a list of beneficiaries that support the provided currency.
   *
   * @public
   * @async
   * @function getBeneficiaries
   * @param currency - The currency (ISO 4217 Alphabetic Code) for which to retrieve beneficiaries.
   * @returns A Promise that resolves to an array of beneficiary objects.
   * @throws If the network request fails or if the response is invalid.
   * @see {@link https://openapi-master.staging.qonto-cbs.co/international-out#tag/Beneficiaries/operation/getBeneficiaries | Endpoint documentation}
   */
  async getBeneficiaries(currency: string): Promise<Beneficiary[]> {
    const url = this.#buildURL('beneficiaries', { currency });
    const { beneficiaries } = await this.networkManager.request(url);
    return transformKeys(beneficiaries);
  }

  /**
   * Gets the account details of a beneficiary.
   *
   * @public
   * @async
   * @function getBeneficiaryDetails
   * @param beneficiaryId - The ID of the beneficiary.
   * @returns A Promise that resolves to a list of the beneficiary's account details.
   * @throws If the network request fails or if the response is invalid.
   * @see {@link https://openapi-master.staging.qonto-cbs.co/international-out#tag/Beneficiaries/operation/getBeneficiaryDetails | Endpoint documentation}
   */
  async getBeneficiaryDetails(beneficiaryId: string): Promise<BeneficiaryAccountDetail[]> {
    const url = this.#buildURL(`beneficiaries/${beneficiaryId}`);
    const { localizedData } = await this.networkManager.request(url);
    return transformKeys(localizedData);
  }

  /**
   * Retrieves the beneficiary requirements for a given quote (and an optional beneficiary).
   * The data returned can be used to build a dynamic user interface for beneficiary creation or edition,
   * as it helps figuring out which fields are required to create a valid beneficiary for different currencies.
   *
   * @public
   * @async
   * @function getBeneficiaryRequirements
   * @param params - The parameters object.
   * @param params.quoteId - The ID of the quote.
   * @param params.currency - The target currency (ISO 4217 Alphabetic Code).
   * @param [params.beneficiary] - The beneficiary object to refresh the requirements for.
   * @param [params.beneficiary.id] - The ID of the selected beneficiary.
   * @param [params.beneficiary.paymentType] - The payment type of the selected beneficiary.
   * @param [params.details] - The details containing the form data.
   * @returns A promise that resolves to the beneficiary requirements.
   * @throws If the network request fails or if the response is invalid.
   * @see {@link https://openapi-master.staging.qonto-cbs.co/international-out#tag/Beneficiaries/operation/getBeneficiaryRequirements | Endpoint documentation}
   */
  async getBeneficiaryRequirements({
    quoteId,
    currency,
    beneficiary = {},
    details = {},
  }: {
    quoteId: string;
    currency: string;
    beneficiary?: Partial<Pick<Beneficiary, 'id' | 'paymentType'>>;
    details?: FormData;
  }): Promise<Requirements[]> {
    const { id, paymentType: type } = beneficiary;
    const url = this.#buildURL('beneficiary-requirements');
    const { requirements } = await this.networkManager.request(url, {
      method: 'POST',
      data: JSON.stringify({
        quoteId,
        ...(id && { beneficiaryId: id }),
        payload: {
          ...(type && { type }),
          currency,
          details: removeNullValues(details),
        },
      }),
    });
    return transformKeys(requirements);
  }

  /**
   * Fetches the eligibility information of the current organization regarding outgoing international transfers.
   *
   * @public
   * @async
   * @function getEligibilityStatus
   * @returns A promise that resolves to the eligibility information of the current organization.
   * @throws If the network request fails or if the response is invalid.
   * @see {@link https://openapi-master.staging.qonto-cbs.co/international-out#tag/Organization-eligibility/operation/getEligibilityOfOrganization | Endpoint documentation}
   */
  async getEligibilityStatus(): Promise<Eligibility> {
    const url = this.#buildURL('eligibility');
    const eligibilityStatus = await this.networkManager.request(url);
    const formattedEligibilityStatus = transformKeys(eligibilityStatus);
    this.#eligibilityStatus.set(
      this.organizationManager.organization.id,
      formattedEligibilityStatus
    );
    return formattedEligibilityStatus;
  }

  /**
   * Retrieves the last available eligibility information of the current organization regarding outgoing international transfers.
   * It fetches it if it has not been fetched yet. Otherwise, it returns the cached value.
   *
   * @public
   * @async
   * @function getLastEligibilityStatus
   * @returns A promise that resolves to the eligibility information.
   * @throws If the network request fails or if the response is invalid.
   */
  async getLastEligibilityStatus(): Promise<Eligibility> {
    return (
      this.#eligibilityStatus.get(this.organizationManager.organization.id) ??
      (await this.getEligibilityStatus())
    );
  }

  /**
   * Fetches the available target currencies based on the provided source currency.
   *
   * @public
   * @async
   * @function getTargetCurrencies
   * @param [sourceCurrency="EUR"] - The source currency (ISO 4217 Alphabetic Code).
   * @returns A Promise that resolves to the list of available target currencies for the given currency code.
   * @throws If the network request fails or if the response is invalid.
   * @see {@link https://openapi-master.staging.qonto-cbs.co/international-out#tag/Currencies/operation/getTargetCurrencies | Endpoint documentation}
   */
  async getTargetCurrencies(sourceCurrency = DEFAULT_SOURCE_CURRENCY_CODE): Promise<Currency[]> {
    const url = this.#buildURL('currencies', { source: sourceCurrency });
    const { currencies } = await this.networkManager.request(url);
    return transformKeys(currencies).map(normalizeCurrency);
  }

  /**
   * Retrieves the transfer requirements for a given quote.
   * The data returned can be used to build a dynamic user interface for transfer creation,
   * as it helps figuring out which fields are required to create a valid transfer.
   *
   * @public
   * @async
   * @function getTransferRequirements
   * @param params - The parameters object.
   * @param params.quoteId - The ID of the quote.
   * @param params.targetAccountId - The ID of the beneficiary stored by the outgoing international transfer provider.
   * @param [params.details] - The details containing the form data.
   * @returns A promise that resolves to the transfer requirements.
   * @throws If the network request fails or if the response is invalid.
   * @see {@link https://openapi-master.staging.qonto-cbs.co/international-out#tag/Transactions/operation/transferRequirements | Endpoint documentation}
   */
  async getTransferRequirements({
    quoteId,
    targetAccountId,
    details = {},
  }: {
    quoteId: string;
    targetAccountId: number;
    details?: FormData;
  }): Promise<Requirements[]> {
    const url = this.#buildURL('transfer-requirements');
    const { requirements } = await this.networkManager.request(url, {
      method: 'POST',
      data: JSON.stringify({
        quoteId,
        targetAccountId,
        payload: {
          details: removeNullValues(details),
        },
      }),
    });
    return transformKeys(requirements);
  }

  /**
   * Retrieves the transfer timeline for a given transfer.
   * The timeline represents the usual chain of events of a processing transfer (creation, validation, shipment, and completion).
   *
   * @public
   * @async
   * @function getTransferTimeline
   * @param transferId - The ID of the transfer.
   * @returns A promise that resolves to the transfer timeline.
   * @throws If the network request fails or if the response is invalid.
   * @see {@link https://openapi-master.staging.qonto-cbs.co/international-out#tag/Transactions/operation/getTimeline | Endpoint documentation}
   */
  async getTransferTimeline(transferId: string): Promise<ProcessingEvent[]> {
    const url = this.#buildURL(`transfers/${transferId}/timeline`);
    const { timeline } = await this.networkManager.request(url);
    return transformKeys(timeline);
  }

  /**
   * Checks if the current organization is eligible for outgoing international transfers.
   *
   * @public
   * @async
   * @function isEligible
   * @returns A promise that resolves to a `boolean` indicating whether the current organization is eligible for outgoing international transfers.
   */
  async isEligible(): Promise<boolean> {
    try {
      const { status } = await this.getLastEligibilityStatus();
      return status === ELIGIBILITY_STATUS.ELIGIBLE;
    } catch {
      return false;
    }
  }

  /**
   * Updates the beneficiary details.
   *
   * @public
   * @async
   * @function updateBeneficiary
   * @param params - The parameters object.
   * @param params.quoteId - The ID of the quote.
   * @param params.beneficiaryId - The ID of the beneficiary to update.
   * @param params.currency - The currency of the beneficiary to update.
   * @param params.type - The payment type to associate the beneficiary with.
   * @param [params.details] - The new attributes to update the beneficiary with.
   * @returns A promise that resolves to an object containing the patched quote, the updated beneficiary, the transfer fees and the target account ID.
   * @throws If the network request fails or if the response is invalid.
   * @see {@link https://openapi-master.staging.qonto-cbs.co/international-out#tag/Beneficiaries/operation/updateBeneficiary | Endpoint documentation}
   */
  async updateBeneficiary({
    quoteId,
    beneficiaryId,
    currency,
    type,
    details = {},
  }: {
    quoteId: string;
    beneficiaryId: string;
    currency: string;
    type: string;
    details?: FormData;
  }): Promise<{
    beneficiary: Beneficiary;
    fees: Fees;
    quote: Quote;
    targetAccountId: ProviderAccount['id'];
  }> {
    const url = this.#buildURL(`beneficiaries/${beneficiaryId}`);
    const response = await this.networkManager.request(url, {
      method: 'PUT',
      data: JSON.stringify({
        quoteId,
        payload: {
          currency,
          details,
          type,
        },
      }),
    });
    const { fees, quote, ...rest } = transformKeys(response);
    return { fees: normalizeFee(fees), quote: normalizeQuote(quote), ...rest };
  }

  /**
   * Builds a URL path with an optional query string.
   *
   * @private
   * @function buildPath
   * @param endpoint - The base endpoint.
   * @param [params={}] - An object containing query parameters.
   * @returns The URL path with the query string, if applicable.
   * @example
   * buildPath('/beneficiaries', { id: '123' }); // '/users?id=123'
   * buildPath('/transfers', { category: ['suppliers', 'salaries'] }); // '/transfers?category=suppliers,salaries'
   */
  #buildPath(endpoint: string, params: Record<string, string | string[]> = {}): string {
    if (!Object.keys(params).length) {
      return endpoint;
    }

    const formattedParams = transformKeys(params, decamelize);
    const isValid = (value?: string | null): boolean => !isNil(value);
    const queryString = Object.entries(formattedParams)
      .map(([key, value]) => {
        if (Array.isArray(value) && value.length) {
          return `${key}=${value.filter(isValid).map(encodeURIComponent).join(',')}`;
        }

        if (!Array.isArray(value) && isValid(value)) {
          return `${key}=${encodeURIComponent(value)}`;
        }

        return null;
      })
      .filter(Boolean)
      .join('&');

    return queryString ? `${endpoint}?${queryString}` : endpoint;
  }

  /**
   * Constructs a URL for outgoing international transfer requests by appending the provided path to the base URL.
   *
   * @private
   * @function buildURL
   * @param endpoint - The endpoint to be appended to the base URL.
   * @param [params={}] - An object containing query parameters.
   * @returns The constructed URL.
   */
  #buildURL(endpoint: string, params: Record<string, string | string[]> = {}): string {
    return `${apiBaseURL}/${internationalOutNamespace}/international_out/${this.#buildPath(endpoint, params)}`;
  }
}

declare module '@ember/service' {
  interface Registry {
    'international-out-manager': InternationalOutManager;
    internationalOutManager: InternationalOutManager;
  }
}
