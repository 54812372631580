import { LIMIT_TYPES, WARNINGS } from 'qonto/constants/international-out/confirmation';
import { PAY_IN } from 'qonto/constants/international-out/quote';

/**
 * @typedef {Object} Amount
 * @property {string} value
 * @property {string} currency - The currency code (ISO 4217 Alphabetic Code) associated to the amount.
 */

/**
 * @typedef {Object} SpendingLimits
 * @property {Amount} currentMonthSpendings - An amount representing the current month transfer spendings for the membership.
 * @property {Amount} monthlyTransferLimits - An amount representing the monthly transfer limit set for the membership.
 * @property {Amount} perTransferLimit - An amount representing the per transfer limit set for the membership.
 */

/**
 * Normalizes the confirmation data by checking warning statuses and spending limits.
 *
 * @param {Confirmation} confirmationData - The confirmation data object.
 * @property {Array<string>} warnings - A list of warning codes as strings. The list can be empty.
 * @property {SpendingLimits} [spendLimits] - The spending limits data of the membership. Defined only if the list of warning codes contains a code related to spending limits.
 * @returns {Object} Normalized confirmation data object.
 */
export function normalizeConfirmation({ warnings, spendLimits = {} }) {
  let isAbovePerTransferLimit = warnings.includes(WARNINGS.INSUFFICIENT_PER_TRANSFER_LIMIT);
  let isAboveMonthlyTransferLimit = warnings.includes(WARNINGS.INSUFFICIENT_MONTHLY_LIMIT);

  let isAboveLimit = isAbovePerTransferLimit || isAboveMonthlyTransferLimit;

  let canProcess = !isAboveLimit && !warnings.includes(WARNINGS.INSUFFICIENT_FUNDS);

  let limitType = isAbovePerTransferLimit
    ? LIMIT_TYPES.PER_TRANSFER
    : isAboveMonthlyTransferLimit
      ? LIMIT_TYPES.MONTHLY
      : null;

  let spendings = {
    currentMonth: Number(spendLimits.currentMonthSpendings?.value || 0),
    monthlyLimit: Number(spendLimits.monthlyTransferLimit?.value || 0),
    perTransferLimit: Number(spendLimits.perTransferLimit?.value || 0),
  };

  return {
    warnings,
    canProcess,
    isAboveLimit,
    ...(isAboveLimit && {
      limitType,
      spendings,
    }),
  };
}

/**
 * Normalizes currency data.
 *
 * @param {Object} currency - Currency data object.
 * @param {string} currency.currencyCode -  The currency code (ISO 4217 Alphabetic Code).
 * @returns {Object} Normalized currency data object.
 */
export function normalizeCurrency({ currencyCode, ...rest }) {
  return {
    code: currencyCode,
    ...rest,
  };
}

/**
 * Normalizes transfer fee data.
 *
 * @param {Object} fee - Transfer fee data object.
 * @param {Amount} fee.fixedFeeAmount - Object containing fixed fee amount.
 * @param {Amount} fee.minimumFeeAmount - Object containing minimum fee amount.
 * @param {Amount} fee.totalFeeAmount - Object containing total fee amount.
 * @param {number} fee.variableFeeRate - Variable fee rate.
 * @returns {Object} Normalized fee data object with fixed, total, and variable fees parsed as number.
 */
export function normalizeFee({
  fixedFeeAmount,
  minimumFeeAmount,
  totalFeeAmount,
  variableFeeRate,
}) {
  return {
    fix: Number(fixedFeeAmount.value),
    minimum: Number(minimumFeeAmount.value),
    total: Number(totalFeeAmount.value),
    variable: Number(variableFeeRate),
  };
}

/**
 * Normalizes transfer quote data.
 *
 * @param {Object} quote - Transfer quote data object.
 * @param {string} quote.id - Transfer quote ID.
 * @param {string} quote.expirationTime - Transfer quote expiration time.
 * @param {Object[]} quote.paymentOptions - Array of payment options available for the transfer quote.
 * @param {string} quote.payOut - The mechanism used to deliver the transfer (e.g. "SHA").
 * @param {'SOURCE'|'TARGET'} providedAmountType - Whether the quote was created as "SOURCE" or "TARGET".
 * @param {number} quote.rate - Transfer quote rate.
 * @param {string} quote.rateType - Transfer quote rate type (`FIXED` or `FLOATING`).
 * @returns {Object} Normalized quote data object with essential payment details.
 */
export function normalizeQuote({
  id,
  expirationTime,
  paymentOptions,
  payOut: mainPayOut,
  providedAmountType: type,
  rate,
  rateType,
}) {
  let {
    estimatedDelivery,
    formattedEstimatedDelivery,
    payIn,
    payOut,
    sourceAmountVal,
    sourceCurrency,
    targetAmountVal,
    targetCurrency,
  } =
    paymentOptions.find(option => option.payOut === mainPayOut && option.payIn === PAY_IN.LOCAL) ||
    paymentOptions[0];

  return {
    id,
    estimatedDelivery,
    expirationTime,
    formattedEstimatedDelivery,
    payIn,
    payOut,
    rate,
    rateType,
    sourceAmount: Number(sourceAmountVal),
    sourceCurrency,
    targetAmount: Number(targetAmountVal),
    targetCurrency,
    type,
  };
}
