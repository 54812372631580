import Service, { service, type Registry as Services } from '@ember/service';

import { BASE_PATH, HOST, TOKEN } from 'qonto/constants/qontent';

export default class QontentService extends Service {
  @service declare intl: Services['intl'];
  @service declare sentry: Services['sentry'];

  declare baseURL: string;
  declare defaultHeaders: Record<string, string>;

  constructor() {
    super(...arguments);

    this.baseURL = `${HOST}${BASE_PATH}`;
    this.defaultHeaders = {
      'Content-Type': 'application/vnd.api+json',
    };
  }

  async fetchData(endpoint: string, queryParams: Record<string, string> = {}) {
    let url = new URL([this.baseURL, endpoint.replace(/^\//, '')].join('/'));

    url.searchParams.append('api_token', TOKEN);
    url.searchParams.append('language', this.intl.primaryLocale || 'en');

    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        url.searchParams.append(key, value);
      }
    });

    try {
      let response = await fetch(url, {
        method: 'GET',
        headers: this.defaultHeaders,
      });

      if (!response.ok) {
        throw new Error(`[QontentService] Error: ${response.statusText} for endpoint ${endpoint}`);
      }

      return await response.json();
    } catch (error) {
      this.sentry.captureException(error);

      throw new Error(
        `[QontentService] Error: ${error instanceof Error ? error.message : 'unknown'}`
      );
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    qontent: QontentService;
  }
}
